@import "../../variables.scss";

.loader {
    background: #1e1e1e;
    background-image: url(../../../public//images/ladrillos.jpg);
    //background-size: cover;
    height: 100vh;

    .loader-bg {
        padding-top: 20vh;
        height: 100vh;
        background: #0000006b;
    }

    .loading-logo {
        width: 100%;
        max-width: 250px;
        border-radius: 100%;
    }

    .vertical-line {
        height: 15vh;
        width: 5px;
        border-radius: 5px;
    }

    .horizontal-line {
        height: 5px;
        width: 15vh;
        border-radius: 5px;
    }

    .left{
        &.blue-line-v {
            background-image: linear-gradient($blue, $darkBlue);
        }
        &.blue-line-h {
            background-image: linear-gradient(to right, $blue, $darkBlue);
        }
        
        &.pink-line-v {
            background-image: linear-gradient($purple, $pink);
        }
    
        &.pink-line-h {
            background-image: linear-gradient(to right, $pink, $purple);
        }
    }

    .right{
        &.blue-line-v {
            background-image: linear-gradient($blue, $darkBlue);
        }
        &.blue-line-h {
            background-image: linear-gradient(to right, $darkBlue, $blue);
        }
        
        &.pink-line-v {
            background-image: linear-gradient($purple, $pink);
        }
    
        &.pink-line-h {
            background-image: linear-gradient(to right, $purple, $pink);
        }
    }

    .loader-container {
        position: relative;
        height: 40px;
    }

    .loader-container>div {
        position: relative;
        display: inline-block;
        background: #03A9F4;
        height: 100%;
        width: 10px;
        margin: 0;
        -webkit-animation: load 3s ease-in-out infinite;
        animation: load 3s ease-in-out infinite;
    }

    .loader-container .rectangle-2 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    .loader-container .rectangle-3 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .loader-container .rectangle-4 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .loader-container .rectangle-5 {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .loader-container .rectangle-6 {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }

    @-moz-keyframes load {

        0%,
        100% {
            -moz-transform: scaleY(1);
            background: #03A9F4;
        }

        16.67% {
            -moz-transform: scaleY(3);
            background: #FF5722;
        }

        33.33% {
            -moz-transform: scaleY(1);
            background: #FF5252;
        }

        50% {
            -moz-transform: scaleY(3);
            background: #E91E63;
        }

        66.67% {
            -moz-transform: scaleY(1);
            background: #9C27B0;
        }

        83.34% {
            -moz-transform: scaleY(3);
            background: #673AB7;
        }
    }

    @-webkit-keyframes load {

        0%,
        100% {
            -webkit-transform: scaleY(1);
            background: #03A9F4;
        }

        16.67% {
            -webkit-transform: scaleY(3);
            background: #FF5722;
        }

        33.33% {
            -webkit-transform: scaleY(1);
            background: #FF5252;
        }

        50% {
            -webkit-transform: scaleY(3);
            background: #E91E63;
        }

        66.67% {
            -webkit-transform: scaleY(1);
            background: #9C27B0;
        }

        83.34% {
            -webkit-transform: scaleY(3);
            background: #673AB7;
        }
    }

    @keyframes load {

        0%,
        100% {
            transform: scaleY(1);
            background: #03A9F4;
        }

        16.67% {
            transform: scaleY(3);
            background: #FF5722;
        }

        33.33% {
            transform: scaleY(1);
            background: #FF5252;
        }

        50% {
            transform: scaleY(3);
            background: #E91E63;
        }

        66.67% {
            transform: scaleY(1);
            background: #9C27B0;
        }

        83.34% {
            transform: scaleY(3);
            background: #673AB7;
        }
    }
}